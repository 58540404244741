import React, { useCallback, useEffect, useMemo } from "react";
import { getIconForPrelevement } from "./expertises/expertises.utils";
import { translations, getLang } from "./expertises/translations";
import { Info } from "./expertises/components/info";
import { useUmbracoData } from "./expertises/services/load.hook";
import { UIStage } from "./expertises/store/expertises.store";
import CategoryButtons from "./expertises/components/category-buttons";
import Breadcrumbs from "./expertises/components/breadcrumbs";

type Props = {
  id: number;
};

export default function Expertises({ id }: Props) {
  const [
    data,
    searchExpertises,
    searchResults,
    filteredData,
    familles,
    selectCategory,
    selectedCategory,
    selectFamille,
    selectExpertise,
    selectedExpertise,
    reset,
    currentStage,
    htmlDescription,
    searchTerm,
    selectedFamille,
    setCurrentStage,
    autoSearchResults,
    setAutoSearchResults
  ] = useUmbracoData(id);

  console.log("data", data);

  const specif = selectedExpertise?.Specificites?.replace(
    /(<p[^>]+?>|<p>|<\/p>)/gim,
    ""
  );

  useEffect(() => {
    if (currentStage == UIStage.Home) {
      const introModules = document.querySelector(".intromodules");
      if (introModules) {
        introModules.style.display =
          currentStage == UIStage.Home ? "block" : "none";
      }
    }
  }, [currentStage]);


  // If the autocomplete dropdown is open and they click outside of it, reset the autoSearchResults
  useEffect(() => {
    const clickOutside = (e) => {
      const dropdown = document.querySelector(".autocomplete-dropdown");
      if (dropdown && !dropdown.contains(e.target)) {
        setAutoSearchResults([]);
      }
    }
    document.addEventListener("click", clickOutside);
    return () => document.removeEventListener("click", clickOutside);
  }, [searchTerm, autoSearchResults]);

  return (
    data && (
      <>
        <div className="mx-auto bg-gradient-to-r from-[#1E347F] to-[#061342] py-16">
          <div className="grid grid-cols-12 gap-4 px-4">
            {(currentStage == UIStage.Home ||
              currentStage == UIStage.Famille ||
              currentStage == UIStage.Expertise) && (
                <div className="col-span-3 hidden lg:block">&nbsp;</div>
              )}
            {currentStage == UIStage.Category && (
              <div className="col-span-3 hidden flex-col gap-2 border-r-[2px] border-white/20 lg:flex">
                <h4 className="text-left text-xl text-white">
                  {translations["decouvrez_dautres_specialites"][getLang()]}
                </h4>
                {data.Categories.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex w-fit cursor-pointer flex-col items-center justify-center rounded-lg border-[1px] border-[#ABBCCF] p-4 py-2 text-center text-sm text-white transition-all hover:border-darkblue-500 hover:bg-white hover:text-darkblue-500"
                      onClick={() => selectCategory(item)}
                    >
                      {item.Name}
                    </div>
                  );
                })}
              </div>
            )}
            <div className="col-span-12 mx-auto flex flex-col justify-center lg:col-span-6">
              <Breadcrumbs
                reset={reset}
                currentStage={currentStage}
                selectCategory={selectCategory}
                selectFamille={selectFamille}
                selectedCategory={selectedCategory}
                selectedExpertise={selectedExpertise}
                selectedFamille={selectedFamille}
                setCurrentStage={setCurrentStage}
              />
              <CategoryButtons
                currentStage={currentStage}
                data={data}
                familles={familles}
                filteredData={filteredData}
                searchResults={searchResults}
                selectCategory={selectCategory}
                selectExpertise={selectExpertise}
                selectFamille={selectFamille}
              />

              <h2 className="mb-6 pb-2 text-center text-[32px] text-white">
                <span className="pb-[2px]">
                  {data.ExpertisesPageModel.Titre}
                </span>
              </h2>

              <div className="relative">
                <input
                  type="text"
                  className="w-full rounded-2xl border-[1px] border-orange-500 p-4 pl-12 text-darkblue-500"
                  placeholder={translations["search_text"][getLang()]}
                  value={searchTerm}
                  onChange={(e) => searchExpertises(e.target.value, true)}
                />
                <i
                  className={`fas fa-search absolute left-4 top-1/2 block -translate-y-1/2 text-xl text-orange-500`}
                />
                {searchTerm.length > 2 && autoSearchResults.length > 0 && (
                  <div className="autocomplete-dropdown absolute top-full left-0 z-50 mt-4 w-full rounded bg-white px-4 py-2 shadow-lg">
                    {autoSearchResults.length > 0 &&
                      <div className="pretty-scrollbar flex max-h-[350px] flex-wrap gap-2 overflow-y-scroll">
                        {autoSearchResults.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="flex w-fit cursor-pointer flex-col items-center justify-center rounded-lg border-[1px] border-[#ABBCCF] p-4 py-2 text-center text-sm text-darkblue-500 transition-all hover:border-darkblue-500 hover:bg-darkblue-500 hover:text-white"
                              onClick={() => selectExpertise(item)}
                            >
                              {item.Titre}
                            </div>
                          );
                        })}
                      </div>
                    }
                  </div>
                )}
              </div>
            </div>
            <div className="col-span-3">&nbsp;</div>
          </div>
        </div>
        <div className="mx-auto max-w-[1530px]">
          {/* {
        accueil && isHome &&
        <div className='formatted-text mt-4' dangerouslySetInnerHTML={{__html: accueil}}></div>
      }  */}
          {htmlDescription && currentStage != UIStage.Expertise && (
            <div
              className="formatted-text mt-4 px-4"
              dangerouslySetInnerHTML={{ __html: htmlDescription }}
            ></div>
          )}
        </div>
        {currentStage == UIStage.Expertise && selectedExpertise && (
          <>
            <div className="my-8 mx-auto max-w-[1530px] px-4">
              {/* <div className="flex items-center justify-center">
                <h1 className="text-2xl">
                  <span className="pb-[7px]">{selectedExpertise?.Titre}</span>
                </h1>
              </div> */}
              <div
                className="result-desc mx-auto max-w-[1530px] p-2 text-blue-500"
                dangerouslySetInnerHTML={{
                  __html: htmlDescription,
                }}
              ></div>
            </div>

            <div className="my-8 mx-auto grid max-w-[1530px] grid-cols-12 gap-4 px-4">
              <div className="col-span-12 grid grid-cols-1 gap-6 border-t-[2px] border-b-[2px] border-blue-500/30 py-4 md:grid-cols-3">
                {selectedExpertise?.TypeDePrelevement &&
                  selectedExpertise?.TypeDePrelevement != "" && (
                    <Info
                      position="left"
                      iconUrl={getIconForPrelevement(
                        selectedExpertise?.TypeDePrelevement
                      )}
                      title={translations["type_de_prelevement"][getLang()]}
                      value={selectedExpertise?.TypeDePrelevement}
                    />
                  )}
                <Info
                  position="middle"
                  iconUrl={
                    selectedExpertise?.AJeun
                      ? "/assets/images/ajeun.svg"
                      : "/assets/images/pasajeun.svg"
                  }
                  title={translations["dois_je_venir_a_jeun"][getLang()]}
                  value={
                    selectedExpertise?.AJeun
                      ? translations["oui"][getLang()]
                      : translations["non"][getLang()]
                  }
                />
                {selectedExpertise?.DelaiResultats &&
                  selectedExpertise?.DelaiResultats != "" && (
                    <Info
                      position="right"
                      iconUrl={"/assets/images/delai.svg"}
                      title={
                        translations["delai_de_rendu_des_resultats_estimatif"][
                        getLang()
                        ]
                      }
                      value={selectedExpertise?.DelaiResultats}
                    />
                  )}
              </div>

              {specif && specif != "" && specif != "Aucun" && (
                <div className="col-span-12">
                  <Info
                    position=""
                    iconUrl={"/assets/images/specificites.svg"}
                    title={translations["specificites"][getLang()]}
                    value={specif}
                  />
                </div>
              )}

              {selectedExpertise?.AJeun && (
                <div className="col-span-12 mx-auto mt-8 max-w-[1530px]">
                  <img
                    src={
                      getLang() == "fr"
                        ? "/assets/images/preparer_fr.svg"
                        : "/assets/images/preparer_en.svg"
                    }
                  />
                </div>
              )}

              <div
                className="infosexamens col-span-12 rounded-2xl bg-bgblue p-7 px-16 text-darkblue-500 md:col-span-8"
                dangerouslySetInnerHTML={{
                  __html: data.ExpertisesPageModel.Texte,
                }}
              />

              <div className="col-span-12 md:col-span-4">
                <div
                  className={`flex flex-col justify-center gap-4 p-4 text-darkblue-500`}
                >
                  <h5 className="text-center">
                    {translations["quelle_est_la_suite"][getLang()]}
                  </h5>
                  {data.ExpertisesPageModel?.LienLaboratoires && (
                    <a
                      href={data.ExpertisesPageModel.LienLaboratoires.Url}
                      className="cta block w-full text-center"
                    >
                      {data.ExpertisesPageModel.LienLaboratoires.Name}
                    </a>
                  )}
                  <button
                    onClick={() => reset()}
                    className="cta block w-full text-center"
                  >
                    {translations["decouvrir_dautres_specialites"][getLang()]}
                  </button>
                  {data.ExpertisesPageModel?.LienParcoursDigital && (
                    <a
                      href={data.ExpertisesPageModel.LienParcoursDigital.Url}
                      className="cta secondary block w-full text-center text-white"
                    >
                      {data.ExpertisesPageModel.LienParcoursDigital.Name}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    )
  );
}
